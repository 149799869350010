/**
 * datepicker
 * @description Initializes Pikaday module for date input fields
 */

import Pikaday from 'pikaday';
import moment from 'moment';
require('moment/locale/de');

export default function plugin(API) {
  API.datepicker = (() => {
    moment.locale('de');
    const selector = document.querySelectorAll('.datepicker');
    const inputHeroCheckIn = document.getElementById('input-hero-check-in');
    const inputHeroCheckOut = document.getElementById('input-hero-check-out');
    const placeholderCheckIn = document.getElementById('field-arrival');
    const placeholderCheckOut = document.getElementById('field-departure');
    const defaults = {
      field: selector,
      format: moment.localeData()._longDateFormat.LL,
      i18n: {
        previousMonth: 'vorheriges Monat',
        nextMonth: 'nächstes Monat',
        months: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        weekdays: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      },
      defaultDate: new Date(),
      firstDay: 1,
      minDate: new Date(),
    };

    const insertDate = (node, date) => {
      let day = moment(date).format('D');
      let month = moment(date).format('MMM');

      let dayEl = node.querySelector('.value-day');
      let monthEl = node.querySelector('.value-month');

      dayEl.innerHTML = day;
      monthEl.innerHTML = month;
    };

    selector.forEach((field) => {
      let options = {
        field: field,
      };

      if (field.classList.contains('date')) {
        options = {
          field: field,
          yearRange: false,
        };

        if (typeof validity_begin !== typeof undefined) {
          //options.defaultDate = new Date(validity_begin);
          options.minDate = new Date(validity_begin);
        }
        if (typeof validity_end !== typeof undefined) {
          options.maxDate = new Date(validity_end);
        }
      }

      if (field === inputHeroCheckIn) {
        options.trigger = placeholderCheckIn;
        options.onSelect = function (date) {
          insertDate(placeholderCheckIn, date);
        };
      }

      if (field === inputHeroCheckOut) {
        options.trigger = placeholderCheckOut;
        options.onSelect = function (date) {
          insertDate(placeholderCheckOut, date);
        };
      }

      new Pikaday(Object.assign({}, defaults, options));
    });
  })();
}
