/*!
 * SITE main application
 * @copyright Copyright 2023
 *
 * Goran Ilic, <ja@ich-mach-das.at>
 * Web: www.ich-mach-das.at
 */

// Core
import { create, boot } from 'core';
import * as utils from 'utils';

import accordionItems from 'plugins/accordionItems.js';
import doubleTapMenu from 'plugins/doubleTapMenu';
import datepicker from 'plugins/datepicker';
import imgLazyLoad from 'plugins/imgLazyLoad';
import initializeLightGallery from 'plugins/initializeLightGallery';
import loadOSMap from 'plugins/loadOSMaps';
import loadWebcamImage from 'plugins/loadWebcamImage';
import splideSlider from 'plugins/initializeSplide';
import processForm from 'plugins/formSubmitHandler';
import scrollToHash from 'plugins/scrollToHash';
import observeStickyElement from 'plugins/observeStickyElement';
import updateAmount from 'plugins/updateAmount';
import toggleHamburgerMenu from 'plugins/toggleHamburgerMenu';

// Import Vendor
import 'vendor/klaro-no-css';
import 'vendor/svgxuse';

//=============================================================
// Config
//=============================================================

const config = {};

//=============================================================
// Options
//=============================================================

const defaultOptions = {
  rootUrl: './',
};

//=============================================================
// Bind API
//=============================================================

const API = create(config);

API.use(accordionItems);
API.use(doubleTapMenu);
API.use(datepicker);
API.use(imgLazyLoad);
API.use(initializeLightGallery);
API.use(loadOSMap);
API.use(loadWebcamImage);
API.use(processForm);
API.use(scrollToHash);
API.use(splideSlider);
API.use(observeStickyElement);
API.use(updateAmount);
API.use(toggleHamburgerMenu);

API.page = document.body.dataset;

API.init = (options) => {
  // Set runtime options
  API.options = Object.assign({}, defaultOptions, options);

  API.tasks.init.run();

  //let manager = klaro.getManager(klaroConfig);
  //console.log(manager);

  // Init gallery plugin
  const galleries = document.querySelectorAll('.items--images');
  for (let gallery of galleries) {
    API.initializeLightGallery(gallery);
  }

  // Splide slider
  API.splideSlider('.container--carousel', {
    perPage: 1,
    type: 'loop',
    perMove: 1,
    focus: 'center',
    padding: '20%',
    gap: '1rem',
    interval: 8000,
    breakpoints: {
      480: {
        padding: '0',
        gap: '.2rem',
      },
      740: {
        padding: '6%',
        gap: '.5rem',
      },
    },
  });

  API.splideSlider('#hero-slider', {
    type: 'fade',
    cover: true,
    interval: 5000,
    arrows: true,
    pagination: false,
    height: '70vh',
    preloadPages: 1,
  });

  // Handle form validation and submit
  const forms = document.querySelectorAll('.form-validate');
  forms.forEach((form) => {
    let errorList = form.querySelector('ul.alerts-validate');

    API.processForm(form, errorList).validateBeforeSubmit();

    if (form.classList.contains('form-ajax'))
      API.processForm(form, errorList).submitWithAjax();
  });

  // Form file attachment
  var inputs = document.querySelectorAll('.attachment');

  Array.prototype.forEach.call(inputs, function (input) {
    var label = input.nextElementSibling,
      labelVal = label.innerHTML;

    input.addEventListener('change', function (e) {
      var fileName = '';

      if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace(
          '{count}',
          this.files.length
        );
      } else {
        fileName = e.target.value.split('\\').pop();
      }

      if (fileName) {
        label.nextElementSibling.innerHTML = fileName;
      } else {
        label.innerHTML = labelVal;
      }
    });
  });

  // hero Booking
  const incrementBtn = document.querySelector('.increase');
  const decrementBtn = document.querySelector('.decrease');
  const peopleInput = document.getElementById('people');

  if (incrementBtn) {
    incrementBtn.addEventListener('click', (e) => {
      e.preventDefault();
      API.updateAmount(peopleInput).increment();
    });
  }
  if (decrementBtn) {
    decrementBtn.addEventListener('click', (e) => {
      e.preventDefault();
      API.updateAmount(peopleInput).decrement();
    });
  }

  // Init location map
  let manager = window.klaro.getManager(klaroConfig);
  const maps = document.querySelectorAll('.map');

  if (maps.length > 0 && manager.getConsent('leaflet')) {
    let container = L.DomUtil.get('map-canvas');
    if (container != null) {
      container._leaflet_id = null;
    }
    maps.forEach((item) => {
      let map = API.loadOSMap();

      let mapData = JSON.parse(item.dataset.map);

      map.createMap(item, { zoom: mapData[0].zoom });
      map.addMarkers(mapData);
    });
  }

  manager.watch({
    update: function (manager, eventType, data) {
      if (maps.length > 0 && manager.getConsent('leaflet')) {
        let container = L.DomUtil.get('map-canvas');
        if (container != null) {
          container._leaflet_id = null;
        }
        maps.forEach((item) => {
          let map = API.loadOSMap();

          let mapData = JSON.parse(item.dataset.map);

          map.createMap(item, { zoom: mapData[0].zoom });
          map.addMarkers(mapData);
        });
      }
    },
  });

  // accordions
  API.accordionItems('.accordion-item');
  // Sticky career badge
  API.observeStickyElement('.page-badge--job', '.job-badge-sentinal');
  // Sticky form
  //API.observeStickyElement('.page-booking-form', '.booking-form-sentinal');
  API.observeStickyElement('.boxes', '.link-boxes-sentinal');
  // Init mobile menu icon
  API.toggleHamburgerMenu('#nav--trigger', '#nav--main');

  API.tasks.ready.run();

  window.addEventListener('unhandledrejection', function (event) {
    console.warn(
      'WARNING: Unhandled promise rejection. Reason: ' + event.reason,
      event
    );
  });
};

//=============================================================
// Boot API
//=============================================================

boot(API);
