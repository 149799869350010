import moment from 'moment';
require('moment/locale/de');

export default function plugin(API) {
  API.loadWebcamImage = (() => {
    moment.locale('de');

    async function fetchImage(url, caption) {
      const options = {
        method: 'GET',
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        const imageBlob = await response.blob();
        //const imageObjectURL = URL.createObjectURL(imageBlob);
        const headers = Object.fromEntries(response.headers.entries());
        const time = moment(headers['last-modified']).format('LLLL');
        caption.innerHTML = time;

        //return imageObjectURL;
      } else {
        console.log('HTTP-Error: ' + response.status);
      }
    }

    const images = document.querySelectorAll('.js-webcam-image');

    if (images.length > 0) {
      images.forEach((img) => {
        const src = img.src;
        const caption = img.nextSibling;
        const interval = 10000; // 10 seconds
        let flag = false;

        fetchImage(src, caption);

        setInterval(function () {
          let date = new Date();
          img.src = src + '?' + date.getTime();
          fetchImage(src, caption);
          flag = !flag;
        }, interval);
      });
    }
  })();
}
