export default function plugin(API) {
  API.observeStickyElement = (el, sentinal) => {
    const element = document.querySelector(el);
    const sentinalElement = document.querySelector(sentinal);

    const handler = (entries) => {
      if (entries[0].boundingClientRect.y < 0) {
        element.classList.add('enabled');
      } else {
        element.classList.remove('enabled');
      }
      // if (!entries[0].isIntersecting) {
      //   element.classList.add('enabled');
      // } else {
      //   element.classList.remove('enabled');
      // }
    };

    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      if (element) {
        const observer = new IntersectionObserver(handler);
        observer.observe(sentinalElement);
      }
    }
  };
}
