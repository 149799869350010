import Splide from '@splidejs/splide';

export default function plugin(API) {
  API.splideSlider = (selector, options) => {
    document.addEventListener('DOMContentLoaded', function () {
      let defaultOptions = {
        type: 'fade',
        rewind: true,
        autoplay: true,
        interval: 4000,
        pagination: false,
      };

      options = Object.assign({}, defaultOptions, options);

      if (document.querySelector(selector)) {
        const splide = new Splide(selector, options);

        splide.mount();
      }
    });
  };
}
