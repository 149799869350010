export default function plugin(API) {
  API.updateAmount = (input) => {
    return {
      increment: () => {
        let value = parseInt(input.value, 10);
        value = isNaN(value) ? 0 : value;
        value++;
        input.value = value;
      },

      decrement: () => {
        let value = parseInt(input.value, 10);
        value = isNaN(value) ? 0 : value;
        value < 2 ? (value = 2) : '';
        value--;
        input.value = value;
      },
    };
  };
}
